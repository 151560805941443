import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { MobileView } from "react-device-detect";
import { AddToHomeScreen } from "react-pwa-add-to-homescreen";
import axios from "axios";
import Popup from "reactjs-popup";
import "../../App.css";
import "./Questionnaires.css";
import { Ellipsis } from "react-spinners-css";
import Header from "./../../components/Header/Header";
import { Link, useHistory } from "react-router-dom";
import prettyMilliseconds from "../../handlers/pretty-ms-hebrew";
import { getGender, getFirstName } from "../../handlers/dictionaryHandler.js";
import Footer from "./../../components/Footer/Footer";
import NotificationManager from "../../utils/NotifcationManager";
import PrayMan from "../../Articles/prayMan";
import PrayWoman from "../../Articles/prayWoman";
import MyPopup from "./../../components/MyPopup/MyPopup";

const Questionnaires = () => {
  const history = useHistory();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [isErrorMessageDisplayed, setIsErrorMessageDisplayed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [aboutMeGood, setAboutMeGood] = useState(0);
  const [aboutPartnerGood, setAboutPartnerGood] = useState(0);
  const [mirorGood, setMirorGood] = useState(0);
  // const [haredGood, setHaredGood] = useState(0);
  const [isDetailsGoodDisplayed, setIsDetailsGoodDisplayed] = useState(false);
  const [messegeAboutDetails, setMessegeAboutDetails] = useState();
  const [prayAreaVisible, setPrayAreaVisible] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const [timeLeft, setTimeLeft] = useState();
  const [areDetailsGood, setAreDetailsGood] = useState(true);
  const [inFreezeUse, setInFreezeUse] = useState(false);
  const [cntMatches, setCntMatches] = useState(0);
  const [questionnaires, setQuestionnaires] = useState(null);
  useEffect(() => {
    const defaultQuestionnaires = [
      // {
      //   type: "me",
      //   text: "מי אני",
      //   to: "/aboutme",
      //   icon: getGender() === "1" ? "aboutmeiconw" : "aboutmeiconm",
      // },
      // {
      //   type: "partner",
      //   text: "מי מתאים לי",
      //   to: "/aboutpartner",
      //   icon: getGender() === "1" ? "aboutpartnericonw" : "aboutpartnericonm",
      // },
      // {
      //   type: "miror",
      //   text: "מראה מראה שעל הקיר",
      //   to: "/miror",
      //   icon: "miroricon",
      // },
      {
        type: "findplace",
        text: "רעיונות לאן לצאת",
        to: "/findplace",
        icon: "findplaceicon",
      },
      {
        type: "edit",
        text: "עריכת הפרטים",
        to: "/edit",
        icon: "editicon",
      },

      {
        type: "match",
        text: "ההתאמות שלי",
        to: "/doublematch",
        getIcon: () => {
          const matchCount = cntMatches;
          if (matchCount == 0) {
            return "doublematchicon";
          } else if (matchCount == 1) {
            return "circle_1icon";
          }

          // 2
          return "circle_2icon";
        },
      },
      // {
      //   type: "forum",
      //   text: "הפורום",
      //   to: "/questionnaires",
      //   icon: "forumicon",
      // },
      // { type: "forum", text: "הפורום", to: "/forumrooms", icon: "forumicon" },

      // {
      //   type: "addplace",
      //   text: "לאן לצאת? רוצה להמליץ!",
      //   to: "/addplace",
      //   icon: "addplaceicon",
      // },
      // {
      //   type: "fun",
      //   text: "פעילויות לדייטים",
      //   to: "/fundates",
      //   icon: "fundatesicon",
      // },

      {
        type: "blog",
        text: "בלוג ",
        to: "/blog",
        icon: "forumicon",
      },

      // {
      //   type: "article",
      //   text: "טיפים ומאמרים",
      //   to: "/article",
      //   icon: "owlicon",
      // },
      // {
      //   type: "doublefriend",
      //   text: "דאבליסטים",
      //   to: "/doublefriend",
      //   icon: "doublefriend",
      // },
      // {
      //   type: "present",
      //   text: 'שת"פים ומבצעים',
      //   to: "/collaboration",
      //   icon: "presenticon",
      // },
    ];

    setQuestionnaires(defaultQuestionnaires);
    if (!Cookies.get("welcome")) {
      // להציג את הפופאפ
      // Cookies
    }
  }, [cntMatches]);

  useEffect(() => {
    try {
      // console.log("Register notification started");
      NotificationManager.register();
    } catch (error) {
      console.log(error);
    }
  }, []);

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  const alertUser = (message) => {
    setIsErrorMessageDisplayed(true);
    setErrorMessage(message);
  };

  const insertFunDate = async (kind) => {
    try {
      // console.log(kind);
      const response = await axios.post(
        process.env.REACT_APP_LOCALHOST_ADDRESS + "/insertFunDates",
        { kind: kind },
        config
      );

      if (response.data === true) {
        //
      }
    } catch (error) {
      //
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);
    // Clear timeout if the component is unmounted
    return () => clearInterval(timer);
  }, []);

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     changeImage();
  //   }, 2500);
  //   return () => clearInterval(timer);
  // }, []);

  const changeImage = () => {
    if (document.getElementById("topad")) {
      if (
        document.getElementById("topad").style.backgroundImage ===
        'url("/topadimgs/topad1.png")'
      ) {
        document.getElementById("topad").style.backgroundImage =
          'url("/topadimgs/topad2.png")';
      } else if (
        document.getElementById("topad").style.backgroundImage ===
        'url("/topadimgs/topad2.png")'
      ) {
        document.getElementById("topad").style.backgroundImage =
          'url("/topadimgs/topad3.png")';
      } else {
        document.getElementById("topad").style.backgroundImage =
          'url("/topadimgs/topad1.png")';
      }
    }
  };

  useEffect(async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_LOCALHOST_ADDRESS + "/pAreDetailseGood",
        config
      );

      localStorage.setItem("lastMatchChosen", 0);

      const userData = response.data;

      setAboutMeGood(userData.aboutMeGood);
      setAboutPartnerGood(userData.aboutPartnerGood);
      setMirorGood(userData.mirorGood);
      // setHaredGood(userData.haredGood);
      setCntMatches(userData.cntMatches);
      localStorage.setItem("cntMatches", userData.cntMatches);
      setInFreezeUse(userData.inFreezeUse);
      setIsDataLoaded(true);

      if (Cookies.get("popupWhatsup")) {
        document.getElementById("popupWhatsup").style.display = "auto";
      } else {
        document.getElementById("popupWhatsup").style.display = "none";
      }

      if (
        userData.aboutMeGood != 1 ||
        userData.aboutPartnerGood != 1 ||
        userData.mirorGood != 1
        //  ||
        // userData.haredGood != 1
      ) {
        // setIsDetailsGoodDisplayed(true);
        // newUser = true;
        setAreDetailsGood(false);
        setIsDetailsGoodDisplayed(true);
        setMessegeAboutDetails(
          ` שימו לב, על מנת לקבל התאמות ביום שלישי, עליכם למלא את כל הפרטים`
        );
      } else {
        // setIsDetailsGoodDisplayed(true);
        // setMessegeAboutDetails(
        //   `יש! מילאתם את כל הפרטים, דאבל מחפש לך התאמות, ויציג אותן ביום שלישי`
        // );
      }
    } catch (error) {
      // probebly user is not authenticated or server is down
    }
  }, []);

  const calculateTimeLeft = () => {
    let nextTuesday = new Date();
    // nextTuesday.setDate(nextTuesday.getDate() + (2 + 7 - nextTuesday.getDay()));
    nextTuesday.setDate(
      nextTuesday.getDate() + ((1 + 7 - nextTuesday.getDay()) % 7)
    );
    nextTuesday.setHours(23);
    nextTuesday.setMinutes(59);
    nextTuesday.setSeconds(59);
    // console.log(nextTuesday);

    return prettyMilliseconds(nextTuesday.getTime() - currentDate.getTime(), {
      compact: false,
      verbose: true,
    });
  };

  const onFreezeUse = async () => {
    setInFreezeUse(!inFreezeUse);
    try {
      const response = await axios.post(
        process.env.REACT_APP_LOCALHOST_ADDRESS + "/freezeUse",
        // send 0 even thogh its the oposite from logic, cause react didnt refresh the value
        { toFreeze: inFreezeUse ? 0 : 1 },
        config
      );

      if (response.data === true) {
        // history.push("/questionnaires");
        // alertUser("");
        setIsErrorMessageDisplayed(false);
      } else {
        alertUser("יש שגיאה בשרת או שאבד החיבור לאינטרנט, אנא נסה שנית");
      }
    } catch (error) {
      alertUser("יש שגיאה בשרת או שאבד החיבור לאינטרנט, אנא נסה שנית");
    }
  };

  const renderQuestionnairesButtons = () => {
    const QuestionnairesButtons = questionnaires.map((questionnaire) => (
      <Link
        to={questionnaire.to}
        style={{ textDecoration: "none" }}
        key={questionnaire.type}
      >
        <div
          // className={
          //   questionnaire.text.includes("פעילויות")
          //     ? // || questionnaire.text.includes("מבצע")
          //       "button questionnaire-btn-border"
          //     : "button questionnaire-btn"
          // }
          className="button questionnaire-btn doubleHeight"
          style={{ height: "50px", lineHeight: "50px" }}
          onClick={() =>
            questionnaire.type === "fun"
              ? insertFunDate(1)
              : questionnaire.type === "findplace"
              ? insertFunDate(2)
              : ""
          }
        >
          <div className="btn-questionnaire-text">
            {questionnaire.text} <div className="error"></div>
          </div>
          <div className="details-not-good">
            {questionnaire.type === "edit" && !areDetailsGood ? "חסר!" : ""}
          </div>
          <div
            className={
              questionnaire.getIcon
                ? questionnaire.getIcon()
                : questionnaire.icon
            }
          ></div>
          <div
            className={questionnaire.type === "fun" ? "vip" : ""}
            style={{ float: "left" }}
          ></div>
        </div>
      </Link>
    ));

    return (
      <div className="questionnaires-buttons">{QuestionnairesButtons}</div>
    );
  };
  return (
    <form className="questionnaries-form">
      {isDataLoaded === true ? (
        <>
          <div className="full-screen questionnaires">
            <Header />
            <div id="sub-header">
              <div
                className="exit-sub-header"
                onClick={() => {
                  document.getElementById("sub-header").style.display = "none";
                }}
              >
                x
              </div>
              <div className="text-sub-header">
                {/* <a href="whatsapp://send/?text=הי מה המצב? אני רוצה להמליץ לך לנסות אתר קצת שונה וממש שווה, הוא שדכן וירטואלי.. זה הקישור: https://double2love.com בתקווה לבשורות טובות ושלום במהרה!">
                  <div
                    className="adtopmainscreen"
                    id="topad"
                    style={{ backgroundImage: 'url("/topadimgs/topad2.png")' }}
                  ></div>
                </a> */}
                {/* <a
                  href={`https://social-events222.com/`}
                  target="_blank"
                  rel="noopener noreferrer"
                  color="white"
                >
                  <div className="izharweb"></div> 
                </a> */}

                {/* <a
                  // href={`https://wa.me/972549624084?text=הי+אשמח+לשמוע+פרטים+על+קופון+קפה+ומאפה. ${getFirstName()}`}
                  href="whatsapp://send/?text=הי מה המצב? אני רוצה להמליץ לך לנסות אתר קצת שונה וממש שווה, הוא שדכן וירטואלי.. זה הקישור: https://double2love.com בתקווה לבשורות טובות ושלום במהרה!"
                  style={{
                    textDecoration: "none",
                    color: "var(--btnText-color)",
                  }}
                >
                  <div className="adencourage"></div>
                </a> */}

                <a
                  href={`https://wa.me/972544964890?text=הי+נדב+אשמח+לשמוע+על+רבע+שעה+אימון+זוגי+מתנה+,+הגעתי+אליך+מאתר+דאבל. ${getFirstName()}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  color="white"
                >
                  <div className="nadavkurayev"></div>
                </a>

                {/* <Link
                  to="/findplace"
                  // style={{ textDecoration: "none" }}
                  className="topadreview"
                >
                  ראית את ההמלצות למקומות לדייט באתר? 🌸
                </Link> */}
                {/* <div className="topadreview"></div>
                <PrevMatches /> */}

                {/* חג שבועות שמח 🌸 */}
              </div>
            </div>
            <div
              onClick={() => {
                if (
                  document.getElementById("slidepanel1").style.width === "200px"
                ) {
                  document.getElementById("slidepanel1").style.width = "0px";
                }
                const popupWhatsupElement =
                  document.getElementById("popupWhatsup");
                if (popupWhatsupElement && popupWhatsupElement.style) {
                  popupWhatsupElement.style.display = "none";
                }
              }}
            >
              <br></br>
              <div className="questionnaires-container">
                <div className="questionnaires-body">
                  <div className="title-questionaries">
                    {" "}
                    {cntMatches > 0
                      ? "מוצגות כעת התאמות  ב- ההתאמות שלי"
                      : inFreezeUse
                      ? "שמת את עצמך בהשהיה, לשינוי - בתחתית המסך"
                      : !areDetailsGood
                      ? "היכנסו לשאלונים ומלאו את הפרטים"
                      : "דאבל מחפש התאמה ומקווה למצוא בעוד "}
                  </div>
                  <div className="time-till-date-counter-contianer">
                    <span className="title-questionaries">
                      {" "}
                      {areDetailsGood && cntMatches === 0 && !inFreezeUse
                        ? `${calculateTimeLeft()}`
                        : " "}{" "}
                    </span>
                  </div>
                  <Popup
                    open={isDetailsGoodDisplayed}
                    contentStyle={{
                      maxWidth: "45%",
                      borderRadius: "5px",
                      direction: "rtl",
                    }}
                    position="right center"
                    onClose={() => setIsDetailsGoodDisplayed(false)}
                    closeOnDocumentClick
                    closeOnEscape
                  >
                    {messegeAboutDetails}
                  </Popup>

                  <div
                    className={
                      localStorage.getItem("newUser") == 1
                        ? "newUserPopup"
                        : "invisible"
                    }
                    id="newUser"
                  >
                    <div
                      className="exit-popup"
                      onClick={() => {
                        document.getElementById("newUser").style.display =
                          "none";
                        localStorage.setItem("newUser", 0);
                      }}
                    >
                      x
                    </div>
                    <div>
                      {" "}
                      {getFirstName()}! יש! קיבלת חודש מנוי מתנה! מקווים שתהיה
                      לך חוויה טובה :)
                    </div>
                  </div>

                  <div
                    className={
                      Cookies.get("newUser") == 1 &&
                      areDetailsGood &&
                      localStorage.getItem("cntExitNewUser") < 3
                        ? "newUserPopup"
                        : "invisible"
                    }
                    id="newUserFP"
                  >
                    <div
                      className="exit-popup"
                      onClick={() => {
                        var y = parseInt(
                          localStorage.getItem("cntExitNewUser")
                        );
                        if (localStorage.getItem("cntExitNewUser") == null) {
                          y = 0;
                        }

                        y = parseInt(y) + 1;
                        localStorage.setItem("cntExitNewUser", y);
                        document.getElementById("newUserFP").style.display =
                          "none";
                      }}
                    >
                      x
                    </div>
                    <div>
                      {" "}
                      {getFirstName()} רוצה לראות מבצע למצטרפים חדשים להיום
                      בלבד?
                      <Link to="/Subscribe"> כאן </Link>
                    </div>
                  </div>
                  {/* <popup
                    contentStyle={{
                      maxWidth: "45%",
                      borderRadius: "5px",
                      direction: "rtl",
                    }}
                    position="right center"
                    closeOnDocumentClick
                    closeOnEscape
                  >
                    <div id="popupWhatsup">
                      <div
                        className="exit-popup"
                        onClick={() => {
                          document.getElementById(
                            "popupWhatsup"
                          ).style.display = "none";
                          Cookies.remove("popupWhatsup");
                        }}
                      >
                        x
                      </div>

                      <div className="lbl">
                        רוצים לקבל הודעה לווטסאפ כשיש התאמה? רשמו לנו הודעה
                        <a
                          href={"https://wa.me/972549624084"}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div
                            className="whatsuplink"
                            style={{ float: "left" }}
                          ></div>
                        </a>
                        <div className="smalllbl">
                          * ההודעות בווטסאפ בדכ מגיעות, בכפוף למדיניות של
                          ווטסאפ. ההודעות במייל מגיעות תמיד.
                        </div>
                      </div>

                      <div className="lbl">
                        קבוצת ווטסאפ לעדכונים
                        <a
                          href={
                            "https://chat.whatsapp.com/CC5ApSvw7lpIYoujWAzHkv"
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div
                            className="whatsuplink"
                            style={{ float: "left" }}
                          ></div>
                        </a>
                      </div>
                    </div>
                  </popup> */}
                  {cntMatches > 0 ? <MyPopup /> : <></>}

                  {renderQuestionnairesButtons()}
                  {/* <div className="lineDice" /> */}

                  <div
                    className="section-title"
                    style={{
                      fontSize: "14px",
                      textDecoration: "underline",
                      margin: "3px",
                    }}
                  >
                    <a
                      href={"https://chat.whatsapp.com/CC5ApSvw7lpIYoujWAzHkv"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      קבוצת ווטסאפ לעדכונים
                    </a>
                  </div>

                  <div
                    className="section-title"
                    style={{
                      fontSize: "11px",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setPrayAreaVisible(!prayAreaVisible);
                    }}
                  >
                    תפילה לזיווג כאן
                  </div>
                  <div
                    className={prayAreaVisible ? "smallArticle" : "invisible"}
                  >
                    {getGender() === "1" ? <PrayWoman /> : <PrayMan />}
                  </div>
                  <div
                    onClick={() => {
                      Cookies.remove("userId");
                      localStorage.setItem("newUser", 0);
                      history.push("/");
                    }}
                    className="button"
                    id="backBtn"
                  >
                    יציאה
                  </div>
                  <br />
                  <div className="toggle-line">
                    <label className="toggle">
                      <input
                        type="checkbox"
                        onChange={onFreezeUse}
                        checked={!inFreezeUse}
                      />
                      <span className="slider-in-toggle"></span>
                    </label>
                    <div className="lbl">
                      {inFreezeUse
                        ? " סליידר לחזור לשימוש באתר "
                        : "סליידר להשהיית שימוש"}
                    </div>
                  </div>

                  {isErrorMessageDisplayed && (
                    <div className="error-message">
                      <h5>{errorMessage} </h5>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <MobileView>
              <AddToHomeScreen
                skipFirstVisit={true}
                translate={{
                  headline: "הוספה למסך הבית",
                  bottomline: "לחיצה כאן",
                  chromiumAddHomeScreen: "הוספה למסך הבית - לחץ כאן",
                  chromiumInstall: "האייקון יוצג על מסך הבית",
                }}
                cookie={{
                  name: "add-to-home-screen5",
                  // expireDays: 180,
                }}
              />
            </MobileView>
          </div>
          <Footer />
        </>
      ) : (
        <div className="loading-data-container">
          <div className="loading-data-div">
            <Ellipsis />
            <h4>טוען את המידע שלך</h4>
          </div>
        </div>
      )}
    </form>
  );
};

export default Questionnaires;
